import { Alert, Button, Card, Col, Form, Row } from 'react-bootstrap'

import logo from '../../assets/logo.svg'
import { NavLink, useHistory, useLocation } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import Routes from '../../routes'
import LoadingSpinner from '../../components/common/LoadingSpinner'
import resetPasswordUseCase from '../use-cases/reset-password-use-case'
import { validate } from 'uuid'

function ResetPassword({ repoFactory }) {
  const location = useLocation()
  const [formData, setFormData] = useState({
    username: location.state && location.state.value,
    code: '',
    newPassword: '',
    verifyNewPassword: ''
  })

  const [isLoading, setIsLoading] = useState(null)
  const [error, setError] = useState(null)
  const [inputErrors, setInputErrors] = useState({})
  const history = useHistory()

  const resetPassword = (e) => {
    e.preventDefault()
    setIsLoading(true)
    resetPasswordUseCase(
      {
        username: formData.username,
        confirmCode: formData.code,
        newPassword: formData.newPassword
      },
      {
        authRepo: repoFactory.authRepo(),
        observer: {
          confirmPasswordSuccess: (response) => {
            setIsLoading(false)
            setError(false)
            history.push(Routes.LOGIN.PATH)
          },
          confirmPasswordFailed: () => {
            setIsLoading(false)
            setError(true)
          }
        }
      }
    )
  }

  const validate = () => {
    const errors = {}

    if (!formData.username) {
      errors.username = 'Username is required'
    }

    if (!formData.code) {
      errors.code = 'Code is required'
    }

    if (!formData.newPassword) {
      errors.newPassword = 'New password is required'
    }

    if (!formData.verifyNewPassword) {
      errors.verifyNewPassword = 'Verify new password is required'
    }

    if (formData.newPassword && formData.verifyNewPassword && formData.newPassword !== formData.verifyNewPassword) {
      errors.noMatch = 'Passwords not match'
    }

    setInputErrors(errors)
  }

  useEffect(() => {
    validate()
  }, [formData])

  return (
    <>
      {isLoading && <LoadingSpinner />}
      <Row className="justify-content-md-center pt-5">
        <Col xs={12} md={5}>
          <div className="text-center">
            <img src={logo} alt="logo" className="mb-5" />
          </div>
          <Card className="shadow-sm">
            <Card.Body>
              {error && (
                <Alert variant="danger" className="text-md">
                  Unable to reset password, please check your inputs and try again
                </Alert>
              )}
              <Form>
                <Form.Group className="mb-3">
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    placeholder="Username"
                    defaultValue={formData.username}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        username: e.target.value
                      })
                    }}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Code</Form.Label>
                  <Form.Control
                    placeholder="Enter code sent to your email"
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        code: e.target.value
                      })
                    }}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>New Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Enter a new password"
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        newPassword: e.target.value
                      })
                    }}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Re-enter Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Verify new password"
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        verifyNewPassword: e.target.value
                      })
                    }}
                  />
                  {inputErrors?.noMatch && <span className="text-danger text-sm">Passwords not match</span>}
                </Form.Group>
                <div className="text-end">
                  <Button
                    variant="primary"
                    type="submit"
                    onClick={resetPassword}
                    disabled={Object.keys(inputErrors).length > 0}
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
          <div className="text-center mt-4">
            <NavLink to="/login" className="text-sm">
              Go to login page
            </NavLink>
          </div>
        </Col>
      </Row>
    </>
  )
}
export default ResetPassword
