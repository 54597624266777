import { Button, Card } from 'react-bootstrap'
import SemiCircularProgressbar from 'components/common/SemiCircularProgressbar/SemiCircularProgressbar'
import dollarPriceFormatter from 'formatters/dollar_price_formatter'
import {
  getColorCharts,
  getEOYPlannedBudget,
  getTotalBudgetAndSpend,
  getUnallocatedBudget
} from 'Budgets/helpers/functions'

const currentMonth = new Date().getMonth()

function BusinessUnitCard({ businessUnit = [], isMonthly, selectedBusinessUnit, setSelectedBusinessUnit }) {
  const { totalBudget, totalSpend, percentage } = getTotalBudgetAndSpend(businessUnit.departments, isMonthly)
  const isSelected = selectedBusinessUnit?.id === businessUnit.id

  const lastMonthData = getTotalBudgetAndSpend(businessUnit.departments, true, currentMonth - 1)
  const color = getColorCharts(percentage)
  const variance = totalSpend - totalBudget

  return (
    <Card className={`card-budget ${isSelected ? 'selected-card' : ''} mb-3`} key={businessUnit.id}>
      <Card.Body>
        <Card.Title className="text-center mb-4">{businessUnit.name}</Card.Title>
        <div className="d-flex justify-content-evenly align-items-center">
          <SemiCircularProgressbar percent={percentage} radio="70" circledContent />
          <div>
            <Card.Subtitle>Spend</Card.Subtitle>
            <h2>{dollarPriceFormatter(totalSpend)}</h2>
            <Card.Subtitle>Planned</Card.Subtitle>
            <h3>{dollarPriceFormatter(totalBudget)}</h3>
          </div>
        </div>

        <Card className={`card-color card-${color} mt-3`}>
          <Card.Body className="d-flex justify-content-between align-items-center">
            <div>
              <Card.Subtitle className="fw-bold">Variance in spend</Card.Subtitle>
              <h2>{dollarPriceFormatter(variance)}</h2>
            </div>
            {isMonthly ? (
              <div className="text-end">
                <Card.Subtitle>Spend Last Month</Card.Subtitle>
                <h3>{dollarPriceFormatter(lastMonthData.totalSpend)}</h3>
              </div>
            ) : (
              <div className="text-end">
                <Card.Subtitle>Unallocated Budget</Card.Subtitle>
                <h3>{dollarPriceFormatter(getUnallocatedBudget(businessUnit.departments))}</h3>
                <Card.Subtitle>EOY Planned</Card.Subtitle>
                <h3>{dollarPriceFormatter(getEOYPlannedBudget(businessUnit.departments))}</h3>
              </div>
            )}
          </Card.Body>
        </Card>
        <div className="text-center mt-4 mb-3">
          <Button
            disabled={isSelected}
            variant={isSelected ? 'secondary' : 'primary'}
            onClick={() => setSelectedBusinessUnit(businessUnit)}
          >
            Details
          </Button>
        </div>
      </Card.Body>
    </Card>
  )
}
export default BusinessUnitCard
