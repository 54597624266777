import React from 'react'

import './footer.scss'

import awsLogo from '../../assets/aws-logo.png'

const Footer = () => {
  return (
    <footer>
      <img src={awsLogo} alt="Powered by AWS" className="footer--logo" />
    </footer>
  )
}

export default Footer
