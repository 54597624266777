const getAllBudgetsNew = ({ year, token }, { observer, budgetsRepo }) => {
  budgetsRepo.getAllBudgetsNew(
    { year, token },
    {
      success: (budgetsResponse) => {
        if (budgetsResponse.error) {
          observer.errorReceivingActuals()
          return
        }

        const budgets = budgetsResponse.data
        observer.receiveBudgets({ budgets })
      },
      failure: () => {
        observer.errorReceivingBudgets()
      }
    }
  )
}

export default getAllBudgetsNew
