import { Card } from 'react-bootstrap'
import DepartmentCard from './DepartmentCard'
import Slider from 'react-slick'

import './Departments.scss'

const slidesToShow = 3

function Departments({ businessUnit, isMonthly, selectedDepartment, setSelectedDepartment }) {
  const departments = businessUnit.departments || []

  const settings = {
    ...(departments.length < slidesToShow && { className: 'slick-align-left' }),
    infinite: false,
    speed: 500,
    slidesToShow,
    slidesToScroll: 1
  }

  return (
    <>
      {departments.length > 0 && (
        <Card className="departments section-gray mb-3">
          <Card.Body>
            <h3>Departments</h3>
            <Slider {...settings}>
              {departments.map((department, i) => (
                <DepartmentCard
                  key={`deparment-card-${i}`}
                  businessUnitName={businessUnit.name}
                  department={department}
                  isMonthly={isMonthly}
                  selectedDepartment={selectedDepartment}
                  setSelectedDepartment={setSelectedDepartment}
                />
              ))}
            </Slider>
          </Card.Body>
        </Card>
      )}
    </>
  )
}
export default Departments
