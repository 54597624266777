import { Card, Col, Row } from 'react-bootstrap'
import Slider from 'react-slick'
import BusinessUnitCard from './BusinessUnitCard'

const slidesToShow = 3

function BusinessUnit({ budgets, isMonthly, selectedBusinessUnit, setSelectedBusinessUnit }) {
  const settings = {
    ...(budgets.length < slidesToShow && { className: 'slick-align-left' }),
    infinite: false,
    speed: 500,
    slidesToShow,
    slidesToScroll: 1
  }

  return (
    <Card className="section-gray mb-3">
      <Card.Body>
        <h3>Business Units</h3>
        <Row>
          <Col>
            <Slider {...settings}>
              {budgets.map((businessUnit, i) => (
                <BusinessUnitCard
                  key={`business-unit-card-${i}`}
                  businessUnit={businessUnit}
                  isMonthly={isMonthly}
                  selectedBusinessUnit={selectedBusinessUnit}
                  setSelectedBusinessUnit={setSelectedBusinessUnit}
                />
              ))}
            </Slider>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
}
export default BusinessUnit
