import {
  getColorCharts,
  getEOYPlannedBudget,
  getSpendQuarter,
  getTotalBudgetAndSpend,
  getUnallocatedBudget
} from 'Budgets/helpers/functions'
import SemiCircularProgressbar from 'components/common/SemiCircularProgressbar/SemiCircularProgressbar'
import { Card, Col, Row } from 'react-bootstrap'

import './Totals.scss'
import dollarPriceFormatter from 'formatters/dollar_price_formatter'

const currentMonth = new Date().getMonth()

function Totals({ budgets = [], isMonthly }) {
  const { totalBudget, totalSpend, percentage } = getTotalBudgetAndSpend(budgets, isMonthly)
  const lastMonthData = getTotalBudgetAndSpend(budgets, true, currentMonth - 1)
  const totalSpendQuarter = getSpendQuarter(budgets)
  const percentageColor = getColorCharts(percentage)

  const variance = totalSpend - totalBudget
  const variancePercentage = Math.floor((Math.abs(variance) / ((totalSpend + totalBudget) / 2)) * 100) || 0
  const variancePercentageColor = getColorCharts(variancePercentage)

  const lastMonthVariance = lastMonthData.totalSpend - lastMonthData.totalBudget
  const unallocatedBudget = getUnallocatedBudget(budgets)

  return (
    <Row>
      <Col>
        <Card className="card-budget total-card card-shadow mb-3">
          <Card.Body className="d-flex">
            <div className="card-progressbar">
              <Card.Title className="mb-5">Spend</Card.Title>
              <SemiCircularProgressbar percent={percentage} radio="120" />
            </div>
            <Card className={`card-color card-${percentageColor}`}>
              <Card.Body>
                <Card.Subtitle>Spend</Card.Subtitle>
                <h2 className="mb-3">{dollarPriceFormatter(totalSpend)}</h2>
                <Card.Subtitle>Planned</Card.Subtitle>
                <h3 className="mb-3">{dollarPriceFormatter(totalBudget)}</h3>
                {isMonthly ? (
                  <>
                    <Card.Subtitle>Last Month Spend</Card.Subtitle>
                    <h3>{dollarPriceFormatter(lastMonthData.totalSpend)}</h3>
                  </>
                ) : (
                  <>
                    <Card.Subtitle>This Quarter Spend</Card.Subtitle>
                    <h3>{dollarPriceFormatter(totalSpendQuarter)}</h3>
                  </>
                )}
              </Card.Body>
            </Card>
          </Card.Body>
        </Card>
      </Col>
      <Col>
        <Card className="card-budget total-card card-shadow mb-3">
          <Card.Body className="d-flex">
            <div className="card-progressbar">
              <Card.Title className="mb-5">Variance In Spend</Card.Title>
              <SemiCircularProgressbar percent={variancePercentage} radio="120" />
            </div>
            <Card className={`card-color card-${variancePercentageColor}`}>
              <Card.Body>
                <Card.Subtitle>Variance In Spend</Card.Subtitle>
                <h2 className="mb-3">{dollarPriceFormatter(variance)}</h2>
                {isMonthly ? (
                  <>
                    <Card.Subtitle>Last Month</Card.Subtitle>
                    <h3 className="mb-3">{dollarPriceFormatter(lastMonthVariance)}</h3>
                  </>
                ) : (
                  <>
                    <Card.Subtitle>Unallocated Budget</Card.Subtitle>
                    <h3 className="mb-3">{dollarPriceFormatter(unallocatedBudget)}</h3>
                    <Card.Subtitle>EOY Planned</Card.Subtitle>
                    <h3 className="mb-3">{dollarPriceFormatter(getEOYPlannedBudget(budgets))}</h3>
                  </>
                )}
              </Card.Body>
            </Card>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  )
}
export default Totals
