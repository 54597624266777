import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-bootstrap'
import Select from 'react-select'
import AppContext from 'context/AppContext'
import Allocation from './Allocation'
import getAllAccounts from '../use-cases/get-all-accounts'
import getAccountNameWithNumber from 'Admin/AccountMapperNew/helpers/getAccountNameWithNumber'

import './Accounts.scss'

const Accounts = (props) => {
  const {
    setError,
    setIsLoading,
    existingAllocations,
    onSuccessfulAllocationChange,
    selectedAccountForRemap,
    allocationYear,
    setAllAccounts
  } = props
  const { repoFactory, sorElements } = useContext(AppContext)
  const [accounts, setAccounts] = useState(null)
  const [accountForMapping, setAccountForMapping] = useState(null)
  const [accountForMappingIsNew, setAccountForMappingIsNew] = useState(false)
  const [accountMappingError, setAccountMappingError] = useState(0)

  const mapSelectedAccount = ({ selectedAccount, isNew }) => {
    setAccountForMapping(selectedAccount)
    setAccountForMappingIsNew(isNew)
  }

  useEffect(() => {
    getAccounts()
  }, [repoFactory, existingAllocations, allocationYear])

  useEffect(() => {
    if (selectedAccountForRemap) {
      mapSelectedAccount({
        selectedAccount: {
          accountId: selectedAccountForRemap.accountId,
          accountName: selectedAccountForRemap.accountName
        },
        isNew: false
      })
    }
  }, [selectedAccountForRemap])

  const getAccounts = () => {
    getAllAccounts(
      { existingAllocations, allocationYear },
      {
        accountMapperRepo: repoFactory.accountMapperRepoNew(),
        observer: {
          accountsReceived: (awsAccounts, allAccounts) => {
            setAllAccounts(allAccounts)
            setAccounts(awsAccounts)
            if (existingAllocations.length > 25) {
              setAccountMappingError(2)
            } else if (existingAllocations.length >= 20) {
              setAccountMappingError(1)
            } else {
              setAccountMappingError(0)
            }

            setError(false)
            setIsLoading(false)
          },
          errorReceivingAccounts: () => {
            setAccounts(null)
            setError(true)
            setIsLoading(false)
          }
        }
      }
    )
  }

  const getAccountsOptions = () => {
    if (existingAllocations.length > 25) {
      return accounts.map((account) => ({
        value: account.accountId,
        label: getAccountNameWithNumber(account),
        isDisabled: true
      }))
    }

    return accounts.map((account) => ({
      value: account.accountId,
      label: getAccountNameWithNumber(account)
    }))
  }

  return (
    accounts && (
      <div className="accounts-with-no-budgets">
        <h1>List of Accounts to Allocate</h1>
        <Row>
          <Col md={4}>
            <label>AWS Account Number</label>
            <Select
              className="account-number-select"
              options={getAccountsOptions()}
              onChange={(option) => {
                if (option) {
                  const account = accounts.find((a) => a.accountId === option.value)

                  mapSelectedAccount({
                    selectedAccount: {
                      accountId: account.accountId,
                      accountName: account.accountName
                    },
                    isNew: true
                  })
                } else {
                  mapSelectedAccount({
                    selectedAccount: null,
                    isNew: false
                  })
                }
              }}
              isClearable
            />
            {accountMappingError === 2 && (
              <span className="text-exceeded-error">You have exceeded your account mapping entitlement</span>
            )}
            {accountMappingError === 1 && <span className="text-exceeded-warning">Low Account Mapping</span>}
          </Col>
          <Col md={12} className="mt-3">
            {accountForMapping && (
              <Allocation
                repoFactory={repoFactory}
                selectedAccount={accountForMapping}
                isNew={accountForMappingIsNew}
                sorElements={sorElements}
                onSuccessfulAllocationChange={onSuccessfulAllocationChange}
                selectedAccountForRemap={selectedAccountForRemap}
                getAccounts={getAccounts}
                {...props}
              />
            )}
          </Col>
        </Row>
      </div>
    )
  )
}

Accounts.propTypes = {
  setError: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired
}

export default Accounts
