import React, { useContext, useEffect, useState } from 'react'
import { Badge } from 'react-bootstrap'
import { getDateRange, getNumberOfDays, getDaysFromPeriod } from '../../helpers/getDateRange'
import dollarPriceFormatter from '../../../formatters/dollar_price_formatter'
import { Status } from '../../constants/TimeCardStatus'
import updateTimeCardStatus from '../../use-cases/update-time-card-status'
import ActionButtons from './ActionButtons'
import AppContext from '../../../context/AppContext'

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons'
import LoadingSpinner from 'components/common/LoadingSpinner'

const columnHelper = createColumnHelper()

const TimeCardsTable = ({
  isProductManager,
  isPortfolioManager,
  inputMonth,
  timeCards,
  getUsageStats,
  budgetsData
}) => {
  const { repoFactory } = useContext(AppContext)
  const [data, setData] = useState([])
  const [sorting, setSorting] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const onAction = ({ action, id }) => {
    setIsLoading(true)
    updateTimeCardStatus(
      {
        token: localStorage.getItem('authToken'),
        id,
        status: action
      },
      {
        summaryRepo: repoFactory.summaryRepo(),
        observer: {
          errorUpdatingTimeCard: () => {
            setIsLoading(false)
          },
          receiveSuccessfulTimeCardUpdate: () => {
            getUsageStats()
            setIsLoading(false)
          }
        }
      }
    )
  }

  const getConditionalSpendColor = (monthlyPlannedBudget, timeCardSpent, numOfDays) => {
    if (!monthlyPlannedBudget || !timeCardSpent || !numOfDays) return 'black'

    const dailyBadget = monthlyPlannedBudget / 30

    const percentage = ((dailyBadget * numOfDays) / timeCardSpent) * 100

    if (percentage > 100) {
      return 'green'
    } else if (percentage === 100) {
      return 'black'
    } else if (percentage > 90) {
      return 'orange'
    }

    return 'red'
  }

  const columns = [
    columnHelper.accessor('product', {
      header: () => 'Product'
    }),
    columnHelper.accessor('spendPeriod', {
      header: () => 'Spend Period'
    }),
    columnHelper.accessor('spend', {
      header: () => 'Spend',
      cell: (props) => {
        return (
          <div
            style={{
              color: getConditionalSpendColor(
                props.row.original.budgets?.[`_${inputMonth - 1}`],
                props.row.original.rawRowData.total,
                props.row.original.numOfDays
              )
            }}
          >
            {props.row.original.spend}
          </div>
        )
      }
    }),
    columnHelper.display({
      id: 'action',
      header: () => 'Action',
      cell: (props) => (
        <div className="d-flex justify-content-evenly">
          <ActionButtons
            id={props.row.original.rawRowData.id}
            isProductManager={isProductManager}
            isPortfolioManager={isPortfolioManager}
            currentStatus={props.row.original.rawRowData.status}
            onAction={onAction}
          />
        </div>
      )
    }),
    columnHelper.display({
      id: 'status',
      header: () => 'Status',
      cell: (props) => (
        <Badge className={`badge ${props.row.original.rawRowData.status}`}>{props.row.original.status}</Badge>
      )
    })
  ]

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel()
  })

  useEffect(() => {
    const budgets = budgetsData?.data || []
    let products = []

    budgets.forEach((budget) => {
      const departments = budget.departments || []
      departments.forEach((department) => {
        const portfolios = department.portfolios || []
        portfolios.forEach((portfolio) => {
          products = products.concat(portfolio.products || [])
        })
      })
    })

    const items = timeCards.map((item) => {
      return {
        id: item.id,
        product: item.element4,
        spendPeriod: getDateRange(item.week, inputMonth, item.year),
        status: Status[item.status],
        spend: dollarPriceFormatter(item.total),
        numOfDays: getNumberOfDays(item.week, inputMonth, item.year),
        rawRowData: item,
        budgets: products.find((product) => product.element4 === item.element4)?.budgets
      }
    })
    setData(items)
  }, [inputMonth, timeCards, budgetsData])

  return (
    <div className="table-responsive" style={{ maxHeight: '390px' }}>
      {isLoading && <LoadingSpinner />}
      <table className="table table-hover table-striped">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id} colSpan={header.colSpan}>
                  {header.isPlaceholder ? null : (
                    <button
                      {...{
                        className: header.column.getCanSort()
                          ? 'd-flex justify-content-between align-items-center user-select-none'
                          : '',
                        onClick: header.column.getToggleSortingHandler()
                      }}
                      className="sorting-button"
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {{
                        asc: <FontAwesomeIcon className="ml-2" icon={faArrowUp} />,
                        desc: <FontAwesomeIcon className="ml-2" icon={faArrowDown} />
                      }[header.column.getIsSorted()] ?? null}
                    </button>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default TimeCardsTable
