import { BudgetStatus } from 'Budgets/constants/BudgetStatus'
import {
  getColorCharts,
  getEOYPlannedBudget,
  getTotalBudgetAndSpend,
  getUnallocatedBudget
} from 'Budgets/helpers/functions'
import CircularProgressbar from 'components/common/CircularProgressbar/CircularProgressbar'
import dollarPriceFormatter from 'formatters/dollar_price_formatter'
import { Button, Card } from 'react-bootstrap'

const currentMonth = new Date().getMonth()

function PortfolioCard({ departmentName, portfolio, isMonthly, setSelectedPortfolio, selectedPortfolio }) {
  const { percentage, totalBudget, totalSpend } = getTotalBudgetAndSpend(portfolio.products, isMonthly)
  const isSelected = selectedPortfolio?.id === portfolio.id

  const lastMonthData = getTotalBudgetAndSpend(portfolio.products, true, currentMonth - 1)
  const color = getColorCharts(percentage)
  const variance = totalSpend - totalBudget

  const hasPendingProducts = portfolio.products.some(
    (product) =>
      product.status === BudgetStatus.PENDING ||
      product.status === BudgetStatus.PENDING_UPDATE ||
      product.status === BudgetStatus.REQUIRE_RESCHEDULE
  )

  const customClass = hasPendingProducts ? 'card-border-yellow' : isSelected ? 'selected-card' : ''

  return (
    <Card className={`card-budget ${customClass}  mb-3`}>
      <Card.Body>
        <div className="department-header">
          <div>
            <Card.Subtitle>Department</Card.Subtitle>
            <h5>{departmentName}</h5>
          </div>
          <div className="text-end">
            <Card.Subtitle>Portfolio</Card.Subtitle>
            <h5>{portfolio.name}</h5>
          </div>
        </div>

        <Card className={`card-outline-${color} mb-3`}>
          <Card.Body className="d-flex justify-content-between align-items-center">
            <CircularProgressbar radio="50" percent={percentage} color={color} />
            <div className="text-end">
              <Card.Subtitle>Spend</Card.Subtitle>
              <h2>{dollarPriceFormatter(totalSpend)}</h2>
              <Card.Subtitle>Planned</Card.Subtitle>
              <h2>{dollarPriceFormatter(totalBudget)}</h2>
            </div>
          </Card.Body>
        </Card>

        <Card className={`card-outline-${color} mb-3`}>
          <Card.Body className="d-flex justify-content-between align-items-center">
            <div>
              <h5 className="text-gray mb-1">Variance in spend</h5>
              <h2>{dollarPriceFormatter(variance)}</h2>
            </div>
            <div className="text-end">
              {isMonthly ? (
                <>
                  <Card.Subtitle>Spend Last Month</Card.Subtitle>
                  <h4>{dollarPriceFormatter(lastMonthData.totalSpend)}</h4>
                </>
              ) : (
                <>
                  <Card.Subtitle>Unallocated Budget</Card.Subtitle>
                  <h4>{dollarPriceFormatter(getUnallocatedBudget(portfolio.products))}</h4>
                  <Card.Subtitle>EOY Planned</Card.Subtitle>
                  <h4>{dollarPriceFormatter(getEOYPlannedBudget(portfolio.products))}</h4>
                </>
              )}
            </div>
          </Card.Body>
        </Card>

        <div className="text-center mt-4 mb-3">
          <Button
            disabled={isSelected}
            variant={isSelected ? 'secondary' : 'primary'}
            onClick={() => setSelectedPortfolio(portfolio)}
          >
            Details
          </Button>
        </div>
      </Card.Body>
    </Card>
  )
}
export default PortfolioCard
