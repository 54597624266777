import React, { useContext, useEffect, useState } from 'react'
import AppContext from '../../../context/AppContext'
import LoadingSpinner from '../../../components/common/LoadingSpinner'

import getAllBudgetsNew from 'Budgets/use-cases/getAllBudgetsNew'
import Roles from 'config/Roles'
import VarianceReportNew from 'Actuals/components/variance-report/VarianceReportNew'

const Variance = () => {
  const roles = JSON.parse(localStorage.getItem('roles'))
  const { repoFactory, selectedYear } = useContext(AppContext)
  const [isLoading, setIsLoading] = useState(false)
  const [budgets, setBudgets] = useState(null)

  useEffect(() => {
    setIsLoading(true)
    getAllBudgetsNew(
      {
        year: selectedYear,
        token: localStorage.getItem('authToken'),
        userRole: JSON.parse(localStorage.getItem('roles'))
      },
      {
        budgetsRepo: repoFactory.budgetsRepo(),
        observer: {
          errorReceivingBudgets: () => {
            setIsLoading(false)
          },
          receiveBudgets: ({ budgets }) => {
            if (roles.includes(Roles.FINANCIAL_ADMINS) || roles.includes(Roles.BU_MANAGERS)) {
              const businessUnits = budgets || []
              let products = []

              businessUnits.forEach((businessUnit) => {
                const departments = businessUnit.departments || []
                departments.forEach((department) => {
                  const portfolios = department.portfolios || []
                  portfolios.forEach((portfolio) => {
                    products.push(...portfolio.products)
                  })
                })
              })

              setBudgets(products)
            } else if (roles.includes(Roles.DEPARTMENT_MANAGERS)) {
              const departments = budgets?.[0]?.departments || []
              let products = []

              departments.forEach((department) => {
                const portfolios = department.portfolios || []
                portfolios.forEach((portfolio) => {
                  products.push(...portfolio.products)
                })
              })

              setBudgets(products)
            } else if (roles.includes(Roles.PORTFOLIO_MANAGERS)) {
              const portfolios = budgets?.[0]?.departments?.[0]?.portfolios || []
              let products = []

              portfolios.forEach((portfolio) => {
                products.push(...portfolio.products)
              })

              setBudgets(products)
            } else if (roles.includes(Roles.PRODUCT_MANAGERS)) {
              const products = budgets?.[0]?.departments?.[0]?.portfolios?.[0]?.products || []
              setBudgets(products)
            }
            setIsLoading(false)
          }
        }
      }
    )
  }, [selectedYear])

  return (
    <div className="actuals-data">
      {isLoading && <LoadingSpinner />}

      {budgets && <VarianceReportNew budgetsData={budgets} year={selectedYear} />}
    </div>
  )
}

export default Variance
