import React, { useEffect, useState } from 'react'
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons'
import getColumns from './BillingColumns'
import Roles from 'config/Roles'

const Billing = ({ usageStats: { billings }, budgetsData, timeCards }) => {
  const [data, setData] = useState([])
  const [columns, setColumns] = useState(getColumns(billings[0]))
  const [sorting, setSorting] = useState()

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel()
  })

  const getCriteria = () => {
    const roles = JSON.parse(localStorage.getItem('roles'))
    if (roles.includes(Roles.FINANCIAL_ADMINS) || roles.includes(Roles.BU_MANAGERS)) {
      return 'element1'
    } else if (roles.includes(Roles.DEPARTMENT_MANAGERS)) {
      return 'element2'
    } else if (roles.includes(Roles.PORTFOLIO_MANAGERS)) {
      return 'element3'
    }
    return 'element4'
  }

  useEffect(() => {
    const criteriaName = getCriteria()
    const budgets = budgetsData?.data || []
    let products = []

    budgets.forEach((budget) => {
      const departments = budget.departments || []
      departments.forEach((department) => {
        const portfolios = department.portfolios || []
        portfolios.forEach((portfolio) => {
          products = products.concat(portfolio.products || [])
        })
      })
    })

    const items = billings.map((billing) => {
      const id = billing[criteriaName]
      return {
        ...billing,
        budgets: products.filter((product) => product[criteriaName] === id).map((item) => item.budgets)
      }
    })

    setData([...items])
    setColumns(getColumns(timeCards))
  }, [billings, timeCards, budgetsData])

  return (
    <div>
      <table className="financial-snapshot-table table table-hover table-striped">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id} colSpan={header.colSpan}>
                  {header.isPlaceholder ? null : (
                    <button
                      {...{
                        className: header.column.getCanSort()
                          ? 'd-flex justify-content-between align-items-center user-select-none'
                          : '',
                        onClick: header.column.getToggleSortingHandler()
                      }}
                      className="sorting-button"
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {{
                        asc: <FontAwesomeIcon className="ml-2" icon={faArrowUp} />,
                        desc: <FontAwesomeIcon className="ml-2" icon={faArrowDown} />
                      }[header.column.getIsSorted()] ?? null}
                    </button>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default Billing
