import React, { Fragment, useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import CurrencyInput from 'react-currency-input-field'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'

import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'

const estimateObj = {
  estimateLink: '',
  reference: '',
  startDate: '',
  endDate: '',
  total: ''
}

function getDaysInMonth(year, month) {
  return new Date(year, month, 0).getDate()
}

const Estimates = ({ productId, account, update, isReadOnly }) => {
  const [estimates, setEstimates] = useState(account.breakdown || [])

  useEffect(() => {
    if (account.breakdown?.length) {
      setEstimates(account.breakdown)
    } else if (!isReadOnly) {
      setEstimates([{ ...estimateObj }])
    }
  }, [account.breakdown, isReadOnly])

  const add = () => {
    const newItems = [
      ...estimates,
      {
        ...estimateObj
      }
    ]
    update(productId, account.accountId, newItems)
  }

  const remove = () => {
    const newItems = [...estimates]
    update(productId, account.accountId, newItems.slice(0, -1))
  }

  const onInputChange = (name, value, index) => {
    const newItems = [...estimates]
    newItems[index][name] = value
    update(productId, account.accountId, newItems)
  }

  return (
    <table className="aws-accounts-estimate-table table table-bordered">
      <thead>
        <tr>
          <th>Estimate link</th>
          <th>Reference</th>
          <th>Monthly Cost</th>
          <th>Start date</th>
          <th>End date</th>
          {!isReadOnly && <th>Options</th>}
        </tr>
      </thead>
      <tbody>
        {estimates.length === 0 && (
          <tr>
            <td colSpan={6} className="text-muted text-center">
              No Estimates
            </td>
          </tr>
        )}
        {estimates.map((estimate, index) => (
          <Fragment key={`estimate-${index}`}>
            {isReadOnly ? (
              <tr>
                <td>
                  <a href={estimate.estimateLink} target="_blank" rel="noreferrer">
                    {estimate.estimateLink}
                  </a>
                </td>
                <td>{estimate.reference}</td>
                <td className="text-center">
                  {estimate.total ? estimate.total.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : ''}
                </td>
                <td className="text-center">
                  {estimate.startDate ? moment(estimate.startDate).format('MM/DD/YY') : ''}
                </td>
                <td className="text-center">{estimate.endDate ? moment(estimate.endDate).format('MM/DD/YY') : ''}</td>
              </tr>
            ) : (
              <tr>
                <td>
                  <input
                    name="estimateLink"
                    value={estimate.estimateLink || ''}
                    onChange={({ target }) => onInputChange(target.name, target.value, index)}
                  />
                </td>
                <td>
                  <input
                    name="reference"
                    value={estimate.reference || ''}
                    onChange={({ target }) => onInputChange(target.name, target.value, index)}
                  />
                </td>
                <td>
                  <CurrencyInput
                    prefix="$"
                    name="total"
                    placeholder="Monthly Cost from Estimate"
                    value={estimate.total}
                    decimalsLimit={2}
                    onValueChange={(value) => onInputChange('total', value, index)}
                    className="text-center"
                  />
                </td>
                <td>
                  <DatePicker
                    autoComplete="off"
                    name="startDate"
                    selected={estimate.startDate ? new Date(estimate.startDate) : ''}
                    onChange={(date) => onInputChange('startDate', date, index)}
                    filterDate={(date) => {
                      const day = date.getDate()
                      return day === 1
                    }}
                    placeholderText="Click to select a start date"
                  />
                </td>
                <td>
                  <DatePicker
                    autoComplete="off"
                    name="endDate"
                    selected={estimate.endDate ? new Date(estimate.endDate) : ''}
                    onChange={(date) => onInputChange('endDate', date, index)}
                    filterDate={(date) => {
                      const day = date.getDate()
                      const d = new Date(date)
                      const currentYear = d.getFullYear()
                      const currentMonth = d.getMonth() + 1
                      return day === getDaysInMonth(currentYear, currentMonth)
                    }}
                    placeholderText="Click to select a end date"
                  />
                </td>
                <td className="estimate-options">
                  {index === estimates.length - 1 && (
                    <>
                      <button type="button" onClick={add}>
                        <FontAwesomeIcon icon={faPlusCircle} className="plus-icon" color="green" />
                      </button>
                      <button type="button" onClick={remove}>
                        <FontAwesomeIcon icon={faTimesCircle} className="remove-icon" color="red" />
                      </button>
                    </>
                  )}
                </td>
              </tr>
            )}
          </Fragment>
        ))}
      </tbody>
    </table>
  )
}

export default Estimates
